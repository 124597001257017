$(document).ready(function(){

	// Wrap select boxes in a div
	$('.form-select').wrap("<div class='selectStyle'></div>");

	// Menu plus icons
	$('.mainNavigation li ul').siblings('a').addClass('hasChildren').before('<a class="plus" href="#" />');
	//$('.sectionNavigation li ul').siblings('a').before('<a class="plus" href="#" />');
	$('.plus').click(function () {
		var plus = $(this);
	    plus.toggleClass('plusOpen').siblings('ul').slideToggle('fast');
	    plus.parent('li').toggleClass('open');
	    return false;
	});

	// Mobile menu toggle
	function closeMenu() {
		$('body').removeClass('menuOpen');
		$('.cover').fadeOut('fast');
		$('.siteNavigation').removeClass('open').animate({
			right: "-=390px"
		}, 300, function() {
			$('.siteNavigation').hide();
		});
	}

	$('.menuToggle').click(function(){
		if(!$('body').hasClass('menuOpen')) {
			$('body').addClass('menuOpen');
			if(!$('.cover').length) { 
				$('body').append('<div class="cover"></div>');
			}
			$('.cover').fadeIn('fast');
			$('.siteNavigation').addClass('open').show().animate({
				right: "+=390px"
			}, 300 );
		} else {
			closeMenu();
		}
		
		return false;
	});
	$('.menuClose').click(function(){
		closeMenu();
		return false;
	});
	$('.cover').click(function(){
		closeMenu();
		return false;
	});


	// Open links with rel="external" in new window
    $('a[rel="external"]').click(function() { window.open($(this).attr('href')); return false; });


});